<template>
  <b-card title="Pending Review Warranty">
    <b-col cols="12">
      <!-- <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" @click="toAddTermCondition" variant="success">
            Add Term Condition
          </b-button>
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            :items="PendingReview"
            show-empty
          >
            <template #cell(updatedAt)="row">
              {{row.item.updatedAt | formatDate}}
            </template>
            <template #cell(cert)="row">
              {{row.item.cert?row.item.cert:'-' }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit && row.item.status !== 'Completed'"
                >
                  <b-button
                    variant="outline-primary"
                    @click="toEditPendingReview(row.item._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item style="text-align:center">
                  <b-button @click="toViewPendingReview(row.item._id)"  variant="info">
                    <feather-icon icon="EyeIcon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="PendingReview.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { userAccess } from "@/utils/utils";
import { dateFormat } from "@/utils/utils";

export default {
  data() {
    return {
      fields: [
        { key: "ID", label: "ID", filterable: true, sortable: true },
        { key: "status", label: "Status", filterable: true, sortable: true },
        { key: "cert", label: "Cert", filterable: true, sortable: true },
        { key: "updatedBy", label: "Last Updated", filterable: true, sortable: true },
        { key: "updatedAt", label: "Updated", filterable: true, sortable: true },
        { key: "actions", label: "Action" },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch("PendingReview/fetchPendingReview");
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    ...mapActions({
    }),
    toViewPendingReview(id) {
      this.$router.push(`/warranty/pending-review-detail/${id}`);
    },
    toEditPendingReview(id) {
      this.$router.push(`/warranty/pending-review-edit/${id}`);
    },
  },
  computed: {
    ...mapState({
      PendingReview: (state) => state.pendingReview.pendingReview,
    }),
    permission() {
      return userAccess("Pending Review", "warranty_menu");
    },
    PendingReview() {
      return this.$store.getters["PendingReview/getPendingReview"];
    },
  },
  created() {
    document.title = "Pending Review Warranty | RSP";
  },
};
</script>
